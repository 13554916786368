import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import CustomModal from './components/CustomModal';

function Zone() {
  const [ZoneData, setZoneData] = useState([]);
  const [users, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addMode, setAddMode] = useState(true);
  const [rowData, setRowData] = useState({});
  const [selectedUser, setSelectedUser] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const [filterValue, setFilterValue] = useState('');

  const filteredZoneData = ZoneData.filter(zone => {
    const searchString = `${zone.FMBZoneId} ${zone.Title} ${zone.Address} ${zone.ZonalIncharge} ${zone.ITS}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getZoneData`, {});
      if (response.status === 200) {
        if (response.data.success === false) {
          
          setZoneData([]);
        } else {
          setZoneData(response.data);
          // console.log('filtered data')
          // console.log(filteredZoneData)
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAllUsers`, {});
      if (response.status === 200) {
        if (response.data.success === false) {
          setUserData([]);
        } else {
          setUserData(response.data);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateZone = async (ZoneId, FMBZoneId, title, zonalInchargeITS, address) => {
    // console.log('update zone')
    // console.log('ZoneId: ', ZoneId, 'FMBZoneId: ', FMBZoneId)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateZone`, { ZoneId, FMBZoneId, title, zonalInchargeITS, address });
      if (response.data.success) {
        await fetchData();
       // fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addZone = async (FMBZoneId, title, zonalInchargeITS, address) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNewZone`, { FMBZoneId, title, zonalInchargeITS, address });
      if (response.data.success) {
        await fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEdit = (data) => {
    // console.log('edit data')
    // console.log(data)
    setAddMode(false);
    setRowData(data);
    setSelectedUser(data.ITS);
    setShowModal(true);
  };

  const handleAddNewZone = () => {
    setAddMode(true);
    setRowData({});
    setSelectedUser('');
    setShowModal(true);
  };

  const handleSaveNew = (updatedData) => {
    // console.log('new zone info')
    // console.log(updatedData)
    if (updatedData.FMBZoneId === null || updatedData.FMBZoneId === undefined || updatedData.FMBZoneId === '') {
      setValidationMessage('Zone Id is required');
      return;
    }
    setValidationMessage('');
    addZone(updatedData.FMBZoneId, updatedData.Title, updatedData.ITS, updatedData.Address);
    setShowModal(false);
    setRowData({});
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setRowData({});
    setSelectedUser('');
  };

  const handleSave = (updatedData) => {
    // console.log('edit zone info')
    // console.log(updatedData)
    if (updatedData.FMBZoneId === null || updatedData.FMBZoneId === undefined || updatedData.FMBZoneId === '') {
      setValidationMessage('Zone Id is required');
      return;
    }
    setValidationMessage('');
    updateZone(updatedData.ZoneId, updatedData.FMBZoneId, updatedData.Title, updatedData.ITS, updatedData.Address);
    setShowModal(false);
    setRowData({});
  };

  useEffect(() => {
    fetchData();
    fetchUsers();
  }, []);

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '70px', paddingBottom: '70px', overflowY: 'auto' }}>
        <Container style={{ width: '100%', maxWidth: '900px', padding: '0 15px' }}>
          <Row className="mt-4">
          <Col xs={6}>
              <input
                type="text"
                style={{float:'left'}}
                placeholder="Search..."
                value={filterValue}
                onChange={handleFilterChange}
              />
            </Col>
            <Col xs={6} style={{ textAlign: 'right', marginBottom: '15px' }}>
              <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={handleAddNewZone}>Add New Zone</Button>
            </Col>
          </Row>
          <Row>
            
          </Row>
          {ZoneData && ZoneData.length > 0 ? (
            <Row>
              <Col xs={12}>
                <div style={{ overflowX: 'auto' }}>
                  <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px', minWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>Zone Id</th>
                        <th>Title</th>
                        <th>Address</th>
                        <th>Zonal Incharge</th>
                        <th>Zonal Incharge ITS</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                    {filteredZoneData.map(zone => (
                     // {ZoneData.map(zone => (
                        <tr key={zone.ZoneId}>
                          <td>{zone.FMBZoneId}</td>
                          <td>{zone.Title}</td>
                          <td>{zone.Address}</td>
                          <td>{zone.ZonalIncharge}</td>
                          <td>{zone.ITS}</td>
                          <td>
                            <Button variant="primary" onClick={() => handleEdit(zone)} startIcon={<EditIcon />}></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              {/* Placeholder for empty table */}
            </Row>
          )}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{addMode ? 'Add New Zone' : 'Edit Zone'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                {/* {!addMode && (
                  <div className="mb-3">
                    <label htmlFor="Id" className="form-label">Id</label>
                    <input type="text" className="form-control" id="ZoneId" value={rowData.ZoneId || ''} disabled />
                  </div>
                )} */}
                <div className="mb-3">
                  <label htmlFor="zoneId" className="form-label">Zone Id<span style={{ color: 'red' }}> *</span></label>
                  {/* <input type="text" className="form-control" id="FMBZoneId" value={rowData.FMBZoneId || ''} onChange={(e) => setRowData({ ...rowData, FMBZoneId: e.target.value })} required /> */}

                  {rowData.FMBZoneId !== null && rowData.FMBZoneId !== undefined ? (
    <input
      type="text"
      className="form-control"
      id="FMBZoneId"
      value={rowData.FMBZoneId}
      onChange={(e) => setRowData({ ...rowData, FMBZoneId: e.target.value })}
      required
    />
  ) : (
    <input
      type="text"
      className="form-control"
      id="FMBZoneId"
      value=""
      onChange={(e) => setRowData({ ...rowData, FMBZoneId: e.target.value })}
      required
    />
  )}


                  {validationMessage && <div className="text-danger">{validationMessage}</div>}
                </div>

                <div className="mb-3">
                  <label htmlFor="title" className="form-label">Title</label>
                  <input type="text" className="form-control" id="title" value={rowData.Title || ''} onChange={(e) => setRowData({ ...rowData, Title: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">Address</label>
                  <input type="text" className="form-control" id="address" value={rowData.Address || ''} onChange={(e) => setRowData({ ...rowData, Address: e.target.value })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="zonalIncharge" className="form-label">Zonal Incharge</label>
                  <select className="form-select" id="zonalIncharge" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select Zonal Incharge</option>
                    {users.map((user) => (
                      <option key={user.ITS} value={user.ITS}>{`${user.Name} (${user.ITS})`}</option>
                    ))}
                  </select>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
              <Button variant="primary" onClick={() => addMode ? handleSaveNew({ ...rowData, ZoneId: '', ITS: selectedUser }) : handleSave({ ...rowData, ITS: selectedUser })}>{addMode ? 'Add' : 'Save changes'}</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <Footer />
    </>
  );


}

export default Zone;


import 'bootstrap/dist/css/bootstrap.css'; 
import FMBLogo from '../FMBLogo.png'
import leaf from '../leaf.png'
import {Container, Row, Col} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { AlignHorizontalLeft, Padding } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {MDBContainer,MDBNavbar,MDBNavbarBrand,MDBNavbarNav, MDBNavbarItem,MDBCol, MDBNavbarLink} from 'mdb-react-ui-kit';
import { margin } from '@mui/system';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import CustomModal from './CustomModal';


const Header = (props) => {
    const navigate = useNavigate();
    const [modalType, setModalType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUserResponse = (confirmed) => {
    if (confirmed) {
        localStorage.removeItem('userId')
        localStorage.removeItem('roleId')
        navigate('/login')
    }
    handleCloseModal();
  };

    const onLogOut = () => {
        handleOpenModal('logout');
        // localStorage.removeItem('userId')
        // localStorage.removeItem('roleId')
        // // props.setLoggedIn(true)
        // navigate('/login')
      }
    const Home = () => {
        navigate('/home')
    }
    return (      
    <> <div>
    {modalType === 'logout' && (
      <CustomModal
        open={modalOpen}
        onClose={handleCloseModal}
        modalType="logout"
        onYesClick={() => handleUserResponse(true)}
        onNoClick={() => handleUserResponse(false)}
      />
    )}
  </div>
      <MDBNavbar className='fixed-top' light bgColor='#86C440' style={{ zIndex: '1001', width: '100%', backgroundColor: '#b7cca1' }}>
            <MDBContainer >
            <MDBCol size='2'>
                <MDBNavbarBrand href='#' >
                    <img style={{marginTop:'5%', marginBottom:'5%'}} src={FMBLogo} height='70' alt='' loading='lazy' />
                   {/* {'    '} Thali */}
                </MDBNavbarBrand>
                </MDBCol>
                {/* <MDBCol size='2'></MDBCol>
                <MDBCol size='1'>
                    <img style={{marginTop:'10%'}} src={leaf} height='70em' alt='' loading='lazy' />
                </MDBCol> */}
                <MDBCol size='8'>
                    <h1 className="d-flex align-items-center justify-content-center" style={{fontFamily: 'cursive', color:'#8c6141'}}>Thaali</h1>
                </MDBCol>
                {/* <MDBCol size='1'>
                    <img style={{marginTop:'10%',  transform: "scaleX(-1)"}} src={leaf} height='70em' alt='' loading='lazy' />
                </MDBCol>
                <MDBCol size='3'></MDBCol> */}
                <MDBCol size='1'>
                    <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
                        <MDBNavbarItem>
                            <IconButton aria-label="Previous">
                                <HomeIcon onClick={() => Home()}></HomeIcon>
                            </IconButton>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCol>
                <MDBCol size='1'>
                    <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
                        <MDBNavbarItem>
                            <IconButton aria-label="Previous">
                                <PowerSettingsNewIcon onClick={() => onLogOut()}></PowerSettingsNewIcon>
                            </IconButton>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCol>
            </MDBContainer>
        </MDBNavbar>
<br></br>
<br></br>
      
        {/* <div className="header">
            <div className="header">
            <img src={FBMLogo} width="40%" height="70%" class="img-responsive" alt='Thali'/>
            <h1 style={{marginTop:"10%"}}>Thali</h1>
            <div className="header-right"> </div>
        </div> */}
        
    </>
       );
}
 
export default Header;
import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import XLSX from "xlsx";
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import CustomModal from "../components/CustomModal";
import CircularProgress from '@mui/material/CircularProgress';

function ZonalInchargeReport() {
  const [ZonalInchargeReport, setZonalInchargeReport] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [localStorageData, setLocalStorageData] = useState(null);
  
  const handleOpenModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };
  
  const fetchData = async (e) => {
    try {
      setLoading(true);
      const zoneid = localStorage.getItem('zoneid');
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/getZonalInchargeReport', {zoneid});
      if (response.status = 200) {
        if(response.data.success == false){
          setMessage(true);
          setZonalInchargeReport(null);
          handleOpenModal('report');
          
        }
        else{
          const formattedData = [];
          if (response.data != null && response.data.length > 0) {
            let previousDate = null;
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            
            response.data.forEach(item => {
              // let formattedItem = { ...item };
              // if (Object.prototype.hasOwnProperty.call(item, 'Date')) {
              //   const date = new Date(item.Date);
              //   formattedItem.Date = date.toLocaleDateString('en-GB', options);
              // } 
              delete item.FoodItemNumber;
              if (item.Date !== previousDate && previousDate!== null ) {
                formattedData.push({});
              }
              previousDate = item.Date;
              formattedData.push(item);
            });
          }
          setZonalInchargeReport(formattedData);
          setMessage(response.data.message);
          downloadExcel(e);
        }
      } else {
        setMessage(response.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
    setLoading(false);
  };
  const calculateColumnWidths = (data) => {
    const colWidths = [];
    const keys = Object.keys(data[0]);
    keys.forEach((key) => {
      const maxLength = data.reduce((max, row) => Math.max(max, row[key] ? row[key].toString().length : 0), key.length);
      colWidths.push({ wch: maxLength + 2 }); // Add some padding to the width
    });
    return colWidths;
  };
  const downloadExcel = async (e) => {
    setLoading(true);
    if (ZonalInchargeReport!= null && ZonalInchargeReport.length > 0) {
        e.preventDefault();
        const ws = XLSX.utils.json_to_sheet(ZonalInchargeReport);
        const colWidths = calculateColumnWidths(ZonalInchargeReport);
        ws['!cols'] = colWidths;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "ZonalInchargeReport.xlsx");
    }
    setLoading(false);
  };

  return ( 
    <>
    {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress style={{ color: 'green' }} />
        </div>
      )}
    <Header />
    <div>
        {modalType === 'report' && (
          <CustomModal
            open={modalOpen}
            onClose={handleCloseModal}
            modalType="report"
            onNoClick={handleCloseModal}
          />
        )}
      </div>
    <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '70px', paddingBottom: '70px', overflowY: 'auto', display: 'flex', justifyContent: 'center' }}>
      <Container style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '900px' }}>
        <Row><h3>Zonal Incharge's Report</h3></Row>
        <Row className="justify-content-center">
          <Col md="auto" className="mb-3 mb-md-0">
            <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={fetchData}> Download Report </Button>
          </Col>
          {/* <Col md="auto">
            <Button disabled={ZonalInchargeReport != null && ZonalInchargeReport.length <= 0} style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={downloadExcel}> Download Report </Button>
          </Col> */}
        </Row>
        {/* {ZonalInchargeReport != null && ZonalInchargeReport.length > 0 ? (
          <Row className="mt-4">
            <Col xs={12}>
              <div>
                <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px' }}>
                  <thead>
                      <tr>
                        <th scope="col">Zone</th>
                        <th scope="col">FamilyName</th>
                        <th scope="col">Date</th>
                        <th scope="col">Item</th>
                      </tr>
                  </thead>
                  <tbody>
                      {ZonalInchargeReport.map(report => (
                      <tr key={report.id}>
                        <td>{report.Zone}</td>
                        <td>{report.FamilyName}</td>
                        <td>{report.Date}</td>
                        <td>{report.Item}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
          </Row>
        )} */}
      </Container>
    </div>
    <Footer />
  </>
  )
}

export default ZonalInchargeReport;

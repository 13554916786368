
import React, { Component, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { MDBFooter } from 'mdb-react-ui-kit';
import { bottom } from '@popperjs/core';


function Footer() {

    return (
        <MDBFooter bgColor='light' className='text-center text-lg-left fixed-bottom' >
            <div className='text-center p-1' style={{ backgroundColor: '#c7cfc0'}}>
                &copy; {new Date().getFullYear()} Copyright: Thaali by Faiz Ul Mawaid Il Burhaniyah | powered by {' '}
                <a style={{ color: '#8c6141' }} href="http://www.ITPacific.com.au" target="_blank" rel="noopener noreferrer">
                    ITPACIFIC
                </a>
            </div>
        </MDBFooter>
    );
}

export default Footer;
import Header from "../components/Header";
import Footer from "../components/Footer";
function MenuMakerReport() {
 
  return (
    <div>
        <Header></Header>
            <p>Menu Maker's Report</p>
        <Footer></Footer>
    </div>
  )
}

export default MenuMakerReport;

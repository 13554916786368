import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

const Home = (props) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [userName, setUserName] = useState(localStorage.getItem('username') || '');
  const [zone, setZone] = useState(localStorage.getItem('zone') || '');
  const [zonalIncharge, setZonalIncharge] = useState(localStorage.getItem('zonalIncharge') || '');
  const [zoneAddress, setZoneAddress] = useState(localStorage.getItem('zoneAddress') || '');
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      getUserPermissions();
      getUpdatedUserZone();
    }
    else {
     // setRoleId(null);
      navigate('/login');
    }
  }, []);

  const getUserPermissions = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUserPermissions`, { userId });
      if (response.data.success) {
        setPermissions(response.data.permissions);
        console.log(response.data.permissions)
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getUpdatedUserZone = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUpdatedUserZone`, { userId });
      if (response.data.success) {
        setZone(response.data.Zone);
        setZonalIncharge(response.data.ZonalIncharge);
        setZoneAddress(response.data.Address);
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const viewSchedule = () => navigate('/schedule');
  const viewZones = () => navigate('/Zone');
  const viewUserZones = () => navigate('/UserZone');
  const updateMenu = () => navigate('/MenuMaker');
  const updateRoles = () => navigate('/RolesAndPrivileges');
  const manageUsers = () => navigate('/ManageUser');

  const viewReport = () => {
    if (permissions.includes('ADMIN')) navigate('/AdminReport');
    else if (permissions.includes('COOK')) navigate('/cookReport');
    else if (permissions.includes('ZONAL_INCHARGE')) navigate('/ZonalInchargeReport');
    else if (permissions.includes('MENU_MAKER')) navigate('/MenuMakerReport');
    else if (permissions.includes('DELIVERY_MANAGER')) navigate('/DeliveryManagerReport');
    else if (permissions.includes('DELIVERY_DRIVER')) navigate('/DeliveryDriverReport');
  };

  return (
    <>
      <Header />
      <Container style={{marginTop:'70px', paddingBottom: '25%'}}>
        <Row>
          <Col><h3>Welcome {userName}</h3></Col>
        </Row>
        <Row>
          <Col>
            {zone ? (
              <p style={{ color: '#7e0833' }}>
                Your assigned Zone is: {zone}<br />
                Zonal Incharge: {zonalIncharge}<br />
                Zone Address: {zoneAddress}
              </p>
            ) : (
              <p>No zone has been assigned to you, please reach out to the Admin.</p>
            )}
          </Col>
        </Row>

        <hr />

        <Row>
          <Col><h3>Menu</h3></Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col xs={12} sm={6} md="auto" className="mb-2">
            <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewSchedule}>Select Menu</Button>
          </Col>
          {(permissions.includes('MENU_MAKER') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={updateMenu}>Update Menu</Button>
            </Col>
          )}
        </Row>

        

        {permissions.includes('VIEW_REPORT') && (
          <>
          <hr />
        <Row>
          <Col><h3>Reports</h3></Col>
        </Row>
        <Row className="justify-content-center mb-4">
          
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewReport}>View Reports</Button>
            </Col>
        </Row>
        </>
)}
        

        {(permissions.includes('VIEW_ZONES') || permissions.includes('VIEW_USER_ZONES') || permissions.includes('UPDATE_ROLES') || permissions.includes('MANAGE_USER')
        || permissions.includes('ADMIN')) && (
          <>
          <hr />
           <Row>
           <Col><h3>Manage Entities</h3></Col>
         </Row>
         </>
          )}
        
        <Row className="justify-content-center">
          {(permissions.includes('VIEW_ZONES') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewZones}>Manage Zones</Button>
            </Col>
          )}
          {(permissions.includes('VIEW_USER_ZONES') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={viewUserZones}>Manage User Zones</Button>
            </Col>
          )}
          {(permissions.includes('UPDATE_ROLES') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={updateRoles}>Manage Roles</Button>
            </Col>
          )}
          {(permissions.includes('MANAGE_USER') || permissions.includes('ADMIN')) && (
            <Col xs={12} sm={6} md="auto" className="mb-2">
              <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={manageUsers}>Manage Users</Button>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
    }

export default Home;

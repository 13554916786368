import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';


function CustomModal({ open, onClose, modalType, onYesClick, onNoClick, monthsWithNoItems }) {
  const navigate = useNavigate();

  let modalContent;

  // Define modal content based on modalType
  if (modalType === 'order') {
    modalContent = (
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 350, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography>Your selections have been submitted!</Typography>
        <Button className="float-end" onClick={() => {
          onClose(); 
          navigate('/home');
        }}>Ok</Button>
      </Box>
    );
  } else if (modalType === 'confirmation') {
    modalContent = (
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 350, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography>You haven't selected items for all dates in the following month(s): </Typography>
        <div>
          <ul>
            {monthsWithNoItems.map((month, index) => (
              <li key={index}>{month}</li>
            ))}
          </ul>
        </div>
        <Typography>Are you sure you want to proceed?</Typography>
        <Button className="float-end" onClick={onNoClick}>No</Button>
        <Button className="float-end" onClick={onYesClick}>Yes</Button>
      </Box>
    );
  }
  else if (modalType === 'report') {
    modalContent = (
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography>Data is not available!</Typography>
        <Button className="float-end" onClick={onNoClick}>Close</Button>
      </Box>
    );
  }
  else if (modalType === 'logout') {
    modalContent = (
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 350, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
       
        <Typography>Are you sure you want to Logout?</Typography>
        <Button className="float-end" onClick={onNoClick}>No</Button>
        <Button className="float-end" onClick={onYesClick}>Yes</Button>
      </Box>
    );
  }

  // return (
  //   <Modal open={open} onClose={onClose}>
  //     {modalContent}
  //   </Modal>
  // );

  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ onClick: null }}>
      {modalContent}
    </Modal>
  );
}

export default CustomModal;

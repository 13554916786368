import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import Header from './Header';
import Footer from './Footer';

function Roles() {
  const [roles, setRoles] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [newRoleName, setNewRoleName] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const handleAddRoleModalClose = () => setShowAddRoleModal(false);
  const handleEditRoleModalClose = () => setShowEditRoleModal(false);


  const filteredRoles = roles.filter(role => {
    const searchString = `${role.RoleId} ${role.RoleName}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getRoles`, {});
     // console.log(response.data)
      if (response.status === 200 ) {
      //  console.log(response.data)
        setRoles(response.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEdit = (role) => {
    setSelectedRole(role);
    setNewRoleName(role.RoleName); 
    setShowEditRoleModal(true);
  };

  const handleSave = async () => {
    // console.log(selectedRole)
    // console.log(newRoleName)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateRole`, {
        RoleId: selectedRole.RoleId,
        RoleName: newRoleName,
      });
      if (response.status === 200) {
        setShowEditRoleModal(false);
        setSelectedRole(null);
        setNewRoleName('');
        fetchData();
      } else {
        console.error('Error updating role:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addRole = async (RoleName) => {
    try {
      console.log(RoleName)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNewRole`, { RoleName});
      if (response.data.success) {
        fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddRole = async () => {

    try {
      await addRole(newRoleName);
      handleAddRoleModalClose(); 
    } catch (error) {
      console.error('Error adding role:', error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddNewRole = () => {
    setShowAddRoleModal(true);
  };

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '', paddingBottom: '70px', overflowY: 'auto' }}>
        <Container style={{ width: '100%', maxWidth: '900px', padding: '0 15px' }}>
          <Row className="">
          <Col xs={12} md={6} style={{marginTop:'10px'}}>
            <input
              type="text"
              style={{ width: '100%' }}
              placeholder="Search..."
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </Col>
          <Col md={2}></Col>
          <Col xs={12} md={4} style={{float:'right', textAlign: 'right',marginTop:'10px', marginBottom: '15px' }}>
            <Button variant="contained" style={{ backgroundColor: 'green', borderRadius: '20px',  }} onClick={handleAddNewRole}>Add New Role</Button>
          </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div style={{ overflowX: 'auto' }}>
                <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px', minWidth: '100%' }}>
                  <thead>
                    <tr>
                      <th>Role Id</th>
                      <th>Role Name</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRoles.map(role => (
                      <tr key={role.RoleId}>
                        <td>{role.RoleId}</td>
                        <td>{role.RoleName}</td>
                        <td>
                          <Button variant="primary" onClick={() => handleEdit(role)} startIcon={<EditIcon />}></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Modal show={showAddRoleModal} onHide={handleAddRoleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formRoleName">
                  <Form.Label>Role Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter role name" value={newRoleName} onChange={(e) => setNewRoleName(e.target.value)} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleAddRoleModalClose}>Close</Button>
              <Button variant="primary" onClick={handleAddRole}>Add Role</Button>
            </Modal.Footer>
          </Modal>
    


          <Modal show={showEditRoleModal} onHide={handleEditRoleModalClose}>
  <Modal.Header closeButton>
    <Modal.Title>Edit Role</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formRoleId">
        <Form.Label>Role ID</Form.Label>
        <Form.Control type="text" placeholder="Role ID" value={selectedRole ? selectedRole.RoleId : ''} readOnly />
      </Form.Group>
      <Form.Group controlId="formRoleName">
        <Form.Label>Role Name</Form.Label>
        <Form.Control type="text" placeholder="Enter role name" value={newRoleName} onChange={(e) => setNewRoleName(e.target.value)} />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleEditRoleModalClose}>Close</Button>
    <Button variant="primary" onClick={handleSave}>Save Changes</Button>
  </Modal.Footer>
</Modal>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Roles;

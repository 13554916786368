

import React, { Component, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
    MDBCheckbox, MDBContainer, MDBRow, MDBCard, MDBCol, MDBBadge, MDBCardBody, MDBBtn, MDBIcon, MDBCardFooter
} from 'mdb-react-ui-kit';
import { BorderAllRounded, Padding, WidthFull } from '@mui/icons-material';


function DailyMenu(props) {
    const [selectedValuesLocal, setSelectedValuesLocal] = useState(props.selectedValues);

    useEffect(() => {
        setSelectedValuesLocal(props.selectedValues);
    }, [props.selectedValues]);

    const handleChange = (value) => {
        const newSelectedValues = { ...selectedValuesLocal, [value]: !selectedValuesLocal[value] };
        setSelectedValuesLocal(newSelectedValues);
        props.handleCheckboxChange(newSelectedValues);
        props.handleChangedItems(value, newSelectedValues[value]);
    };

    const formatDate = () => {
        var date = props.date.split(/[ ,]+/)
        return date;
    };

    const isMenuLocked = (date) => {
        const incomingDate = new Date(date);
        const currentDate = new Date();
        const currentWeekStart = new Date(props.currentWeekStart);
        const currentWeekEnd = new Date(currentWeekStart);
        const nextWeekStart = new Date(currentWeekStart);
        const nextWeekEnd = new Date(nextWeekStart);

        nextWeekStart.setDate(nextWeekStart.getDate() + 7);
        nextWeekEnd.setDate(nextWeekEnd.getDate() + 13);
        currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

        incomingDate.setHours(0, 0, 0, 0);
        currentWeekStart.setHours(0, 0, 0, 0);
        currentWeekEnd.setHours(0, 0, 0, 0);

        const isFridayPast9PM = currentDate.getDay() === 5 && currentDate.getHours() >= 21;
        const isWeekend = currentDate.getDay() === 6 || currentDate.getDay() === 0;

        // if ((isFridayPast9PM || isWeekend) && incomingDate > currentWeekEnd && incomingDate <= nextWeekEnd) {
        //     return true;
        // }

        if (isWeekend && incomingDate > currentWeekEnd && incomingDate <= nextWeekEnd) {
            return true;
        }

        return incomingDate >= currentWeekStart && incomingDate <= currentWeekEnd;
    };


    const getCheckboxStyle = (disabled, isChecked) => {

        return {
            opacity: disabled && !isChecked ? 0.5 : 1,
            // color: disabled && isChecked ? '#00000080' : '#000000', 
            // backgroundColor: '#000',
            cursor: disabled ? 'not-allowed' : 'pointer',
        };
    };

    return (
        <MDBContainer style={{ marginBottom: '10px' }}>
            <MDBCard style={{ height: 'fit-content', width: '100%' }}>
                <MDBCardBody>
                    <MDBRow className="g-0">
                        <MDBCol xs="12" sm="4" md="4" lg="3">
                            <MDBCard style={{ marginRight: '1%' }} className="d-flex flex-column h-100">
                                <MDBCardBody className="p-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className='text-muted ' style={{ backgroundColor: '#b7cca1' }}>{formatDate()[0]}</p>
                                            <h2 className='mb-0'>
                                                {formatDate()[2]}
                                                <span className='text-success' style={{ fontSize: '1.3rem' }}>
                                                    <MDBIcon icon='arrow-up' className='ms-1' size='sm' />
                                                    <span> {formatDate()[1]}</span>
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>


                        </MDBCol>


                        <MDBCol xs="12" sm="8" md="8" lg="9">
                            <MDBCard style={{ height: '100%', marginRight: '1%' }}>
                                <MDBCardBody style={{ paddingRight: '18%', paddingLeft: '18%' }}>
                                    <div >
                                        {props.menus && props.menus.length > 0 ? (
                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                {props.menus.map((menuItem, index) => (
                                                    <li key={index} style={{ display: 'flex', marginBottom:'2%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <label style={{ paddingRight: '10%', textAlign: 'left', minWidth: '50%', maxWidth:'80%' }}  onClick={(e) => e.preventDefault()} >{menuItem.food}</label>
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${index}`}
                                                            checked={props.selectedValues[menuItem.ScheduleId]}
                                                            onChange={() => handleChange(menuItem.ScheduleId)}
                                                            onClick={(e) => e.stopPropagation()}
                                                            disabled={isMenuLocked(props.date)}
                                                            style={{
                                                                width: '22px',
                                                                height: '22px',
                                                                marginRight: '5px',
                                                                display: 'inline-block', 
                                                                verticalAlign: 'middle',
                                                                ...getCheckboxStyle(isMenuLocked(props.date), props.selectedValues[menuItem.ScheduleId]) 
                                                            }}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div>No Menu Available</div>
                                        )}

                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>



                    </MDBRow>
                </MDBCardBody>
            </MDBCard>

        </MDBContainer>




    );
}


export default DailyMenu;


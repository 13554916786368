import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import CustomModal from "../components/CustomModal";
import CircularProgress from '@mui/material/CircularProgress';

function DeliveryDriverReport() {
  const [DeliveryReport, setDeliveryReport] = useState([]);
  const [message, setMessage] = useState('');
  const [modalType, setModalType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (type) => {
      setModalType(type);
      setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if(DeliveryReport.length <= 0){
      try {
        setLoading(true);
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/getDeliveryReport', {});
        if (response.status === 200) {
          if(response.data.success === false && DeliveryReport.length <= 0){
            setMessage(true);
            setDeliveryReport(null);
            handleOpenModal('report');
          }
          else{
            const formattedData = [];
            if (response.data != null && response.data.length > 0) {
              let previousDate = null;
              const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
              
              response.data.forEach(item => {
                // let formattedItem = { ...item };
                // if (Object.prototype.hasOwnProperty.call(item, 'Date')) {
                //   const date = new Date(item.Date);
                //   formattedItem.Date = date.toLocaleDateString('en-GB', options);
                // } 
                if (item.Date !== previousDate && previousDate!== null ) {
                  formattedData.push({});
                }
                previousDate = item.Date;
                formattedData.push(item);
              });
              // Set column names
              const columns = Object.keys(formattedData[0]);
              setColumns(columns);
            }
            setDeliveryReport(formattedData);
            setMessage(response.data.message);
          }
        } else {
          setMessage(response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
      setLoading(false);
    }};

    fetchData();
  }, []);

  const calculateColumnWidths = (data) => {
    const colWidths = [];
    const keys = Object.keys(data[0]);
    keys.forEach((key) => {
      const maxLength = data.reduce((max, row) => Math.max(max, row[key] ? row[key].toString().length : 0), key.length);
      colWidths.push({ wch: maxLength + 2 }); // Add some padding to the width
    });
    return colWidths;
  };
  const downloadExcel = async (e) => {
    setLoading(true);
    if (DeliveryReport != null && DeliveryReport.length > 0) {
        // e.preventDefault();
        const ws = XLSX.utils.json_to_sheet(DeliveryReport);
        const colWidths = calculateColumnWidths(DeliveryReport);
        ws['!cols'] = colWidths;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "DeliveryReport.xlsx");
    }
    setLoading(false);
  };

  return (
    <>
    {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress style={{ color: 'green' }} />
        </div>
      )}
    <Header />
    <div>
        {modalType === 'report' && (
          <CustomModal
            open={modalOpen}
            onClose={handleCloseModal}
            modalType="report"
            onNoClick={handleCloseModal}
          />
        )}
      </div>
    <div style={{ minHeight: 'calc(100vh - 56px)', paddingTop: '70px', paddingBottom: '70px' }}>
      <Container style={{ width: '100%', maxWidth: '1000px' }}>
        <Row><h3>Delivery Driver's Report</h3></Row>
        <Row className="justify-content-center">
          {/*<Col md="auto" className="mb-3 mb-md-0">
            <Button style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={fetchData}> Generate Report </Button>
          </Col> */}
           <Col md="auto">
            <Button disabled={DeliveryReport != null && DeliveryReport.length <= 0} style={{ backgroundColor: 'green' }} className="btn btn-primary" variant="contained" onClick={downloadExcel}> Download Report </Button>
          </Col>
        </Row>
        {DeliveryReport != null && DeliveryReport.length > 0 ? (
          <Row className="mt-4">
            <Col xs={12}>
              <div>
                <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px' }}>
                  <thead>
                      <tr>
                        {columns.map(column => (
                          <th key={column} scope="col">{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {DeliveryReport.map((report, index) => (
                      <tr key={index} style={{height: '5%'}}>
                        {columns.map(column => (
                          <td key={column}>{report[column]}</td>
                        ))}
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
          </Row>
        )} 
      </Container>
    </div>
    <Footer />
  </>
  )
}

export default DeliveryDriverReport;

import { HashRouter, Route, Routes } from 'react-router-dom';
import MominHome from './MominHome';
import Home from './Home';
import Login from './Login';
import Zone from './Zone';
import UserZone from './UserZone';
import Users from './Users';
import CookReport from './Reports/CookReport';
import AdminReport from './Reports/AdminReport';
import ZonalInchargeReport from './Reports/ZonalInchargeReport';
import MenuMakerReport from './Reports/MenuMakerReport';
import DeliveryManagerReport from './Reports/AdminReport';
import DeliveryDriverReport from './Reports/DeliveryDriverReport';
import MenuMaker from './MenuMaker';
import RolesAndPrivileges from './RolesAndPrivileges';

import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');

  const isAuthenticated = () => {
    return localStorage.getItem('userId') !== null;
  };

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
              ) : (
                <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />
              )
            }
          />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route
            path="/home"
            element={
              isAuthenticated() ? (
                <Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
              ) : (
                <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />
              )
            }
          />
          <Route path="/schedule" element={isAuthenticated() ? <MominHome /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/MenuMaker" element={isAuthenticated() ? <MenuMaker /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/RolesAndPrivileges" element={isAuthenticated() ? <RolesAndPrivileges /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/cookReport" element={isAuthenticated() ? <CookReport /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/AdminReport" element={isAuthenticated() ? <AdminReport /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/ZonalInchargeReport" element={isAuthenticated() ? <ZonalInchargeReport /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/MenuMakerReport" element={isAuthenticated() ? <MenuMakerReport /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/DeliveryManagerReport" element={isAuthenticated() ? <DeliveryManagerReport /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/DeliveryDriverReport" element={isAuthenticated() ? <DeliveryDriverReport /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/Zone" element={isAuthenticated() ? <Zone /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/UserZone" element={isAuthenticated() ? <UserZone /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/ManageUser" element={isAuthenticated() ? <Users /> : <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {MDBBtn,MDBContainer,MDBRow,MDBCol,MDBInput}from 'mdb-react-ui-kit';
import FMBLogo from './FMBLogo.png'
import { color, maxHeight } from '@mui/system';
import { MDBFooter } from 'mdb-react-ui-kit';
 
function Login(props) {
  const [email, setEmail] = useState('');
  const [its, setUserits] = useState('');
  const [otp, setOTP] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('')
  const [itsError, setItsError] = useState('')
  const [emailSent, setEmailSent] = useState(false);
  const [resendTime, setResendTime] = useState(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (emailSent) {
  //     setResendTime(60); 
  //     const timer = setInterval(() => {
  //       setResendTime(prevTime => prevTime - 1);
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [emailSent]);

  useEffect(() => {
    let timer;
    if (emailSent && resendTime > 0) {
      timer = setInterval(() => {
        setResendTime(prevTime => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer); 
            return 0; 
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [emailSent, resendTime]);

  const handleLogin = async () => {

    setEmailError('')
    setItsError('')

    if ('' === email) {
      setEmailError('Please enter your email')
      console.log(emailError)
      return
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email')
      console.log(emailError)
      return
    }

    if ('' === its) {
      setItsError('Please enter your ITS')
      return
    }

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/login', { email, its });
      if (response.data.success) {
        setMessage('OTP sent to your email!');
        setEmailSent(true); 
        setResendTime(30); 

      } else {
        setItsError('Email or ITS incorrect');
        setMessage(response.data.message);
      }
    } catch (error) {
      setItsError('Email or ITS incorrect');
      console.error('Error:', error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/verifyOTP', { email, otp });
      if (response.data.success) {
        setMessage('OTP verified successfully. You are logged in.');
        props.setLoggedIn(true)
        props.setEmail(email)
	      // setUserid(response.data.userid)
        // setRoleid(response.data.roleid)
        // setZoneid(response.data.zoneid)
        // setUserName(response.data.username)
        localStorage.setItem('userId', response.data.userid);
        localStorage.setItem('roleId', response.data.roleid);
        localStorage.setItem('zoneid', response.data.zoneid);
        localStorage.setItem('zone', response.data.zone);
        localStorage.setItem('username', response.data.username);
        console.log('response: ', response.data)
        navigate('/home')
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleResendEmail = async () => {
    setResendTime(30);
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/sendEmail', { email });

      if (response.data.success) {
        setMessage('OTP sent to your email!');
        setEmailSent(true);
        

      } else {
        setItsError('Email or ITS incorrect');
        setMessage(response.data.message);
      }
     
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
<>
<MDBContainer fluid className="gradient-form p-3 p-md-5 align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
  <MDBRow className="d-flex flex-column align-items-center justify-content-center">
    <MDBCol xs="12" sm="8" md="8" lg="6" xl="5" xxl="4">
      <div className="mt-5 mb-5 text-center">
        <img src={FMBLogo} style={{ width: '175px' }} alt="logo" />
        <h1 className="mt-3 mb-4" style={{ fontFamily: 'cursive', color: '#8c6141' }}>Thaali</h1>
        
      </div>

      <div >
        <MDBInput wrapperClass="m-0" placeholder='Email Address' id='email' type='email' onChange={e => setEmail(e.target.value)} disabled={emailSent}/>
        <label htmlFor="email" className="errorLabel" style={{ color: "red" }}>{emailError}</label>
      </div>
      <div >
        <MDBInput wrapperClass="m-0" placeholder='ITS' id='its' type='text' onChange={e => setUserits(e.target.value)} disabled={emailSent}/>
        <label htmlFor="its" className="errorLabel" style={{ color: "red" }}>{itsError}</label>
      </div>
      <button style={{ backgroundColor: 'green', marginBottom: '3%', width:'100%' }} className="btn btn-primary mb-4" onClick={handleLogin} disabled={emailSent}>Login</button>
      <div className="mb-3">
  <MDBInput wrapperClass="m-0" placeholder='OTP' id='otp' type='text' onChange={e => setOTP(e.target.value)} disabled={!emailSent} />
  <button style={{ backgroundColor: 'green', width:'100%'}} className="btn btn-primary mt-3" onClick={handleVerifyOTP} disabled={!emailSent}>Verify OTP</button>
</div>
      {/* {emailSent && (
  <div className="mb-3">
    <MDBInput wrapperClass="m-0" placeholder='OTP' id='otp' type='text' onChange={e => setOTP(e.target.value)} />
    <button style={{ backgroundColor: 'green', width:'100%'}} className="btn btn-primary mt-3" onClick={handleVerifyOTP}>Verify OTP</button>
  </div>
)} */}
      <label htmlFor="otp" className="errorLabel" style={{ color: "green" }}>{message && <p>{message}</p>}</label>
    
      {emailSent && (
            <div className="mb-3">
              <button className="btn btn-link" onClick={handleResendEmail} disabled={resendTime !== 0}>
                Resend email {resendTime ? `in ${resendTime} seconds` : ''}
              </button>
            </div>
          )}
    
    </MDBCol>
  </MDBRow>
    <MDBFooter bgColor='light' className='text-center text-lg-left fixed-bottom' >
    <p className="mt-3 mb-4" style={{ fontFamily: 'cursive', color: '#8c6141' }}> <sub>{process.env.REACT_APP_CACHE_VERSION}</sub> </p>
    </MDBFooter>
</MDBContainer>


</>
  );
}

export default Login;

import Header from './Header';
import Footer from './Footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function UserRoles() {
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [rowData, setRowData] = useState({});
  const [filterValue, setFilterValue] = useState('');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState(null);

  const handleAddRoleModalClose = () => setShowAddRoleModal(false);
  const handleEditUserModalClose = () => setShowEditUserModal(false);
  const handleDeleteConfirmModalClose = () => setShowDeleteConfirmModal(false);

  const filteredUserData = userRoles.filter(userRole => {
    const searchString = `${userRole.UserId} ${userRole.ITS} ${userRole.RoleId} ${userRole.RoleName} ${userRole.UserName}`.toLowerCase();
    return searchString.includes(filterValue.toLowerCase());
  });

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getUserRoleData`, {});
      if (response.status === 200) {
        setUserRoles(response.data.success === false ? [] : response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getRoles`, {});
      if (response.status === 200) {
        setRoles(response.data.success === false ? [] : response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAllUsers`, {});
      if (response.status === 200) {
        console.log(response.data)
        setUsers(response.data.success === false ? [] : response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateUserRole = async (ITS, RoleId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateUserRole`, { ITS, RoleId });
      if (response.data.success) {
        fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addUserRole = async (UserId, RoleId) => {
    try {
      console.log(UserId, RoleId)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNewUserRole`, { UserId, RoleId });
      if (response.data.success) {
        fetchData();
        setShowAddRoleModal(false);
      } else {
        setValidationMessage(response.data.message || 'Error adding user role');
      }
    } catch (error) {
      console.error('Error:', error);
      setValidationMessage('Internal server error');
    }
  };

  const handleEdit = (data) => {
    setRowData(data);
    setSelectedRoleId(data.RoleId);
    setShowEditUserModal(true);
  };

  const handleSave = () => {
    const updatedITS = rowData.ITS;
    const updatedRoleId = document.getElementById('formRole').value;
    setValidationMessage('');
    updateUserRole(updatedITS, updatedRoleId);
    setShowEditUserModal(false);
    setRowData({});
  };

  const deleteUserRole = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/deleteUserRole`, { UserId: selectedUserRole.UserId, RoleId: selectedUserRole.RoleId });
      if (response.data.success) {
        fetchData();
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDeleteConfirm = () => {
    deleteUserRole();
    setShowDeleteConfirmModal(false);
  };

  const handleDelete = (userRole) => {
    setSelectedUserRole(userRole);
    setShowDeleteConfirmModal(true);
  };

  const handleAddNewRole = () => {
    setSelectedUserId('');
    setSelectedRoleId('');
    setValidationMessage('');
    setShowAddRoleModal(true);
  };

  const handleSaveNewRole = () => {
    if (selectedUserId && selectedRoleId) {
      addUserRole(selectedUserId, selectedRoleId);
    } else {
      setValidationMessage('Please select both user and role');
    }
  };

  useEffect(() => {
    fetchData();
    fetchRoles();
    fetchUsers();
  }, []);

  return (
    <>
      <Header />
      <div style={{ minHeight: 'calc(100vh - 56px)',  paddingTop: '', paddingBottom: '70px', overflowY: 'auto' }}>
        <Container style={{ width: '100%', maxWidth: '900px', padding: '0 15px' }}>
        <Row className="">
          <Col xs={12} md={6} style={{marginTop:'10px'}}>
              <input
                type="text"
                style={{ width: '100%' }}
                placeholder="Search..."
                value={filterValue}
                onChange={handleFilterChange}
              />
            </Col>
            <Col md={2}></Col>
          <Col xs={12} md={4} style={{float:'right', textAlign: 'right',marginTop:'10px', marginBottom: '15px' }}>
              <Button variant="contained" style={{ backgroundColor: 'green', borderRadius: '20px' }} onClick={handleAddNewRole}>Add New User Role</Button>
            </Col>
          </Row>
          {userRoles && userRoles.length > 0 ? (
            <Row>
              <Col xs={12}>
                <div style={{ overflowX: 'auto' }}>
                  <table className="table" style={{ border: '1px solid black', borderColor: 'black', borderWidth: '1px', minWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>User Id</th>
                        <th>ITS</th>
                        <th>User Name</th>
                        <th>Role Id</th>
                        <th>Role Name</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                    {filteredUserData.map(userRole => (
                        <tr key={`${userRole.UserId}-${userRole.RoleId}`}>
                          <td>{userRole.UserId}</td>
                          <td>{userRole.ITS}</td>
                          <td>{userRole.UserName}</td>
                          <td>{userRole.RoleId}</td>
                          <td>{userRole.RoleName}</td>
                          <td>
                          <Button
                              variant="danger"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDelete(userRole)}
                            ></Button>
                          
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} style={{ textAlign: 'center' }}>
                <p>No user roles found.</p>
              </Col>
            </Row>
          )}

          <Modal show={showEditUserModal} onHide={handleEditUserModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update User Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formUserId">
                  <Form.Label>User Id</Form.Label>
                  <Form.Control type="text" placeholder="User Id" value={rowData.UserId} readOnly />
                </Form.Group>
                <Form.Group controlId="formITS">
                  <Form.Label>ITS</Form.Label>
                  <Form.Control type="text" placeholder="ITS" value={rowData.ITS} readOnly />
                </Form.Group>
                <Form.Group controlId="formUserName">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control type="text" placeholder="User Name" value={rowData.UserName} readOnly />
                </Form.Group>
                <Form.Group controlId="formRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Control as="select" value={rowData.RoleId} onChange={(e) => setRowData({ ...rowData, RoleId: e.target.value })}>
                    {roles.map(role => (
                      <option key={role.RoleId} value={role.RoleId}>{role.RoleName}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleEditUserModalClose}>Close</Button>
              <Button variant="primary" onClick={handleSave}>Save Changes</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAddRoleModal} onHide={handleAddRoleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add New User Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formUser">
                  <Form.Label>User</Form.Label>
                  <Form.Control as="select" value={selectedUserId} onChange={(e) => setSelectedUserId(e.target.value)}>
                    <option value="">Select User</option>
                    {users.map(user => (
                      <option key={user.UserId} value={user.UserId}>{user.Name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Control as="select" value={selectedRoleId} onChange={(e) => setSelectedRoleId(e.target.value)}>
                    <option value="">Select Role</option>
                    {roles.map(role => (
                      <option key={role.RoleId} value={role.RoleId}>{role.RoleName}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleAddRoleModalClose}>Close</Button>
              <Button variant="primary" onClick={handleSaveNewRole}>Add</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDeleteConfirmModal} onHide={handleDeleteConfirmModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete User Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this User Role?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteConfirmModalClose}>No</Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>Yes</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default UserRoles;
